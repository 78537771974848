<template>
  <div class="container">
    <div class="ewm page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준일</div>
              <DxDateBox
                :edit-enabled="false"
                :styling-mode="stylingMode"
                width="120"
                height="30"
                v-model="searchType.customTypes.startDt"
                type="date"
                display-format="yyyy.MM.dd"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="mt-1">~</div>
              <DxDateBox
                :styling-mode="stylingMode"
                width="120"
                height="30"
                v-model="searchType.customTypes.endDt"
                type="date"
                display-format="yyyy.MM.dd"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="10"
                :min="searchType.customTypes.startDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator>
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="px-2 pt-1">
                <DxCheckBox class="checkbox" text="상세보기" v-model="searchType.customTypes.checkDetail" />
              </div>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" ref="absenteeismGrid" />

      <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
          </div>
        </template>
        <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
            modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.hasOwnProperty('cancel')
                : !modal.initData.buttons.hasOwnProperty('cancel')
              : false
          "
          :options="{
            elementAttr: {
              class: 'white filled txt_S medium',
            },
            text: modal.initData.hasOwnProperty('buttons')
              ? modal.initData.buttons.hasOwnProperty('cancel')
                ? modal.initData.buttons.cancel.text
                : ''
              : '',
            width: '120',
            height: '40',
            onClick: () => {
              isOpenModal(false);
            },
          }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import ModalAttendanceSchedule from '@/pages/ewm/hr/management/report/attendance/modal-attendance-schedule.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxCheckBox,
      DxPopup,
      DxPosition,
      DxToolbarItem,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      ModalAttendanceSchedule,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            startDt: getPastFromToday(1, 'months'),
            endDt: getPastFromToday(0, 'days'),
            checkDetail: false,
          },
          paramsData: {},
        },
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        originColumns: [
          {
            caption: '부서정보',
            dataField: '서울센터‡예금‡예금1팀',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            calculateCellValue: rowData => {
              return `${rowData.pathNmFull.replace(/‡/g, '>')}`;
            },
          },
          {
            caption: '상담사',
            dataField: 'agtid',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: true,
            calculateCellValue: rowData => {
              return `${rowData.agtNm} [${rowData.agtid}]`;
            },
          },
          {
            caption: '기준일',
            dataField: 'startDt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            calculateDisplayCellValue: rowData => {
              return `${this.$_commonlib.formatDate(rowData.startDt, 'YYYYMMDD', 'YYYY.MM.DD')} ~ 
							${this.$_commonlib.formatDate(rowData.endDt, 'YYYYMMDD', 'YYYY.MM.DD')}`;
            },
          },
          {
            caption: '총 스케줄',
            dataField: 'totalScheduleCnt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = `${options.data.totalScheduleCnt}`;
              aTag.addEventListener('click', () => {
                vm.onScheduleInfo(options.data, 'all');
              });
              container.append(aTag);
            },
          },
          {
            caption: '정상(A)',
            dataField: 'normalCnt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = `${options.data.normalCnt}`;
              aTag.addEventListener('click', () => {
                vm.onScheduleInfo(options.data, 'A');
              });
              container.append(aTag);
            },
          },
          {
            caption: '지각(B)',
            dataField: 'tardinessCnt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = `${options.data.tardinessCnt}`;
              aTag.addEventListener('click', () => {
                vm.onScheduleInfo(options.data, 'B');
              });
              container.append(aTag);
            },
          },
          {
            caption: '결근(C)',
            dataField: 'absentCnt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = `${options.data.absentCnt}`;
              aTag.addEventListener('click', () => {
                vm.onScheduleInfo(options.data, 'C');
              });
              container.append(aTag);
            },
          },
          {
            caption: '조퇴(D)',
            dataField: 'dayoffCnt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = `${options.data.dayoffCnt}`;
              aTag.addEventListener('click', () => {
                vm.onScheduleInfo(options.data, 'D');
              });
              container.append(aTag);
            },
          },
          {
            caption: '휴가(E)',
            dataField: 'vacationCnt',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = `${options.data.vacationCnt}`;
              aTag.addEventListener('click', () => {
                vm.onScheduleInfo(options.data, 'E');
              });
              container.append(aTag);
            },
          },
        ],
        dataGrid: {
          keyExpr: 'agtid',
          refName: 'absenteeismGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 370px)', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서정보',
              dataField: 'pathNmFull',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.pathNmFull.replace(/‡/g, '>')}`;
              },
            },
            {
              caption: '상담사',
              dataField: 'agtid',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtid}]`;
              },
            },
            {
              caption: '기준일',
              dataField: 'startDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${this.$_commonlib.formatDate(rowData.startDt, 'YYYYMMDD', 'YYYY.MM.DD')} ~ 
								${this.$_commonlib.formatDate(rowData.endDt, 'YYYYMMDD', 'YYYY.MM.DD')}`;
              },
            },
            {
              caption: '총 스케줄',
              dataField: 'totalScheduleCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = `${options.data.totalScheduleCnt}`;
                aTag.addEventListener('click', () => {
                  vm.onScheduleInfo(options.data, 'all');
                });
                container.append(aTag);
              },
            },
            {
              caption: '정상(A)',
              dataField: 'normalCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = `${options.data.normalCnt}`;
                aTag.addEventListener('click', () => {
                  vm.onScheduleInfo(options.data, 'A');
                });
                container.append(aTag);
              },
            },
            {
              caption: '지각(B)',
              dataField: 'tardinessCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = `${options.data.tardinessCnt}`;
                aTag.addEventListener('click', () => {
                  vm.onScheduleInfo(options.data, 'B');
                });
                container.append(aTag);
              },
            },
            {
              caption: '결근(C)',
              dataField: 'absentCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = `${options.data.absentCnt}`;
                aTag.addEventListener('click', () => {
                  vm.onScheduleInfo(options.data, 'C');
                });
                container.append(aTag);
              },
            },
            {
              caption: '조퇴(D)',
              dataField: 'dayoffCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = `${options.data.dayoffCnt}`;
                aTag.addEventListener('click', () => {
                  vm.onScheduleInfo(options.data, 'D');
                });
                container.append(aTag);
              },
            },
            {
              caption: '휴가(E)',
              dataField: 'vacationCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                const aTag = document.createElement('a');
                aTag.innerText = `${options.data.vacationCnt}`;
                aTag.addEventListener('click', () => {
                  vm.onScheduleInfo(options.data, 'E');
                });
                container.append(aTag);
              },
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;

        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      async onScheduleInfo(data, symbols) {
        const payload = {
          actionname: 'EWM_HR_ATTENDANCE_DETAIL',
          data: {
            agtid: data.agtid,
            startDt: data.startDt,
            endDt: data.endDt,
            symbols: symbols,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.onOpenModal(
            'ModalAttendanceSchedule',
            {
              title: '근무정보',
              buttons: {
                cancel: { text: '닫기' },
              },
              width: '60%',
              height: '50%',
            },
            res.data.data,
          );
        }
      },
      onChangeDetail() {
        if (this.searchType.customTypes.checkDetail) {
          const dynamicColumns = [...this.originColumns];
          const startYear = parseInt(this.searchType.customTypes.startDt.slice(0, 4)); // 시작일의 연도
          const endYear = parseInt(this.searchType.customTypes.endDt.slice(0, 4)); // 종료일의 연도
          const startMonth = parseInt(this.searchType.customTypes.startDt.slice(4, 6)); // 시작일의 월
          const endMonth = parseInt(this.searchType.customTypes.endDt.slice(4, 6)); // 종료일의 월
          const startDay = parseInt(this.searchType.customTypes.startDt.slice(6, 8)); // 시작일의 일
          const endDay = parseInt(this.searchType.customTypes.endDt.slice(6, 8)); // 종료일의 일

          for (let i = startYear; i <= endYear; i++) {
            const maxMonth = i === endYear ? endMonth : 12;
            const minMonth = i === startYear ? startMonth : 1;

            for (let j = minMonth; j <= maxMonth; j++) {
              const maxDay = i === endYear && j === endMonth ? endDay : new Date(i, j, 0).getDate();

              const monthColumn = {
                //월
                multiHeaderNm: `${j}월`,
                columns: [],
              };

              const start = i === startYear && j === startMonth ? startDay : 1;
              const end = i === endYear && j === endMonth ? endDay : maxDay;

              for (let k = start; k <= end; k++) {
                const date = `${i}${j < 10 ? '0' : ''}${j}${k < 10 ? '0' : ''}${k}`;
                monthColumn.columns.push({
                  //일
                  caption: `${k}일`,
                  dataField: date,
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  cellTemplate: (container, options) => {
                    if (options.data[date]) {
                      const aTag = document.createElement('a');
                      aTag.innerText = `${options.data[date]}`;
                      aTag.addEventListener('click', () => {
                        vm.onScheduleInfo(options.data, options.value);
                      });
                      container.append(aTag);
                    }
                  },
                });
              }

              dynamicColumns.push(monthColumn);
            }
          }
          this.$set(this.dataGrid, 'columns', dynamicColumns);
        } else {
          this.$set(this.dataGrid, 'columns', this.originColumns);
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        /* if(!vm.searchType.customTypes.startDt || !vm.searchType.customTypes.endDt){
				return;
			} */

        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        /* const startDate = new Date();
			startDate.setMonth(parseInt(vm.searchType.customTypes.startDt.slice(4, 6), 10) -1);
			startDate.setDate(parseInt(vm.searchType.customTypes.startDt.slice(6, 8), 10));
			const endDate = new Date();
			endDate.setMonth(parseInt(vm.searchType.customTypes.endDt.slice(4, 6), 10) -1);
			endDate.setDate(parseInt(vm.searchType.customTypes.endDt.slice(6, 8), 10));
			const differenceInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24) + 1); */

        const oneMonthAgo = this.$_commonlib.setCalculateDate(
          'month',
          this.$_commonlib.formatDate(vm.searchType.customTypes.endDt, 'YYYYMMDD', 'YYYY-MM-DD'),
          -1,
        );
        const validStrDate = this.$_commonlib.setCalculateDate('day', oneMonthAgo, -1);
        const cvtStrDate = new Date(this.$_commonlib.formatDate(vm.searchType.customTypes.startDt, 'YYYYMMDD', 'YYYY-MM-DD'));

        if (validStrDate > cvtStrDate) {
          this.$_Toast('최대 1달 기간만 검색이 가능합니다.');
          return;
        }

        const payload = {
          actionname: 'EWM_HR_ATTENDANCE',
          data: {
            startDt: vm.searchType.customTypes.startDt,
            endDt: vm.searchType.customTypes.endDt,
            isDetail: vm.searchType.customTypes.checkDetail == true ? 'Y' : 'N',
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.onChangeDetail();
          if (vm.searchType.customTypes.checkDetail) {
            res.data.data.forEach(item => {
              const details = item.details;
              Object.keys(details).forEach(date => {
                item[date] = details[date];
              });
              delete item.details;
            });
          }
          this.dataGrid.dataSource = res.data.data;
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
